import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import MyDataGrid from './MyDataGrid'
import { COLOURS, otusButtonStyleWhite } from "../variables";
import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from "./ConfirmDialog";
import UsersManagementUsers from "./UsersManagementUsers";
import UsersManagementFunds from "./UsersManagementFund";
import Tracker from "./Tracker";

export function UserManagamentDataGrid({ rows, columns, selectedItem, editable
  , handleDelete, handleAdd, handleRowClick, handleRowEdit }) {
  const [openDialog, setOpenDialog] = useState(false)
  const MyToolbar = () => {
    const style = { color: COLOURS.fontColor }
    const deleteStyle = selectedItem ? { ...style, cursor: 'pointer' } : { color: 'disabled' }
    return (
      <GridToolbarContainer>
        <GridToolbarExport sx={style} />
        <GridToolbarFilterButton sx={style} />
        <AddIcon onClick={(e) => { if (handleAdd) handleAdd(selectedItem) }} sx={{ ...style, cursor: 'pointer' }} />
        <DeleteIcon onClick={(e) => { if (handleDelete && selectedItem) setOpenDialog(true) }} sx={deleteStyle} />
      </GridToolbarContainer>
    );
  }
  return (<div>
    <MyDataGrid rows={rows} handleRowEdit={handleRowEdit} checkboxSelection={false} toolbar={MyToolbar} editable={editable}
      handleRowClick={handleRowClick} columns={columns} />
    <ConfirmDialog open={openDialog} setOpen={setOpenDialog} content='Are you sure you want to delete?'
      handleConfirm={handleDelete} />
  </div>)
}

export default function UsersManagement({ usersClient, fundsClient }) {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false);

  const updateFunds = async () => {
    setLoading(true)
    try {
        await fundsClient.updateFunds();
        alert("Funds updated successfully");
    } catch (error) {
      alert("Something went wrong when updating funds. Please, contact tech team.");
    } finally {
      setLoading(false);
    }
  };

  return (<>
    <Button onClick={updateFunds}
      variant='outlined'
      style={{ ...otusButtonStyleWhite, margin: '30px 30px' }}
      cursor={loading ? "not-allowed" : "pointer"}
    disabled={loading}
   >{loading ? "Processing..." : 'Update Funds'}</Button >
    <Grid container rowSpacing={{ xs: 5 }} p='0px 30px' columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems='center'>
      <Tracker page={'User Management'} />

      <Grid item xs={12} sm={6} md={6}>
        <UsersManagementUsers user={user} setUser={setUser} usersClient={usersClient} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <UsersManagementFunds user={user} usersClient={usersClient} />
      </Grid>
    </Grid></>)
}