
  import configData from '../config.json'
  const address = configData.isProduction ? 'https://otus-website-api.azurewebsites.net'
  :'https://localhost:5001'
  export default class BaseClient {
  constructor(tokenModel, setToken) {
    BaseClient.tokenModel = tokenModel;
    this.setToken = setToken;
    if (new.target === BaseClient) {
      throw new TypeError("Cannot construct Abstract instances directly");
    }
  }
  async refreshToken()
  {
    try{
      const response = await this.request({resource: 'oauth/refresh-token', method: 'POST'
          , body: BaseClient.tokenModel
            , headers: { 'Content-Type' : 'application/json' }
           })
           return response;
    } catch(error){
        return null;
    }
  }
  async request(endpoint = {}) {
    
      const response = await fetch(`${address}/api/${endpoint.resource}`, {
        method: endpoint?.method,
        body: endpoint?.body ? JSON.stringify(endpoint.body) : null,
        headers: endpoint?.headers  ? endpoint.headers : 
        {
          
          'Authorization': 'Bearer '+ BaseClient.tokenModel.token,
          'content-type' : 'application/json'
        }
      });
      if(!response.ok)
      {
        if(response.status == '401') {
          var res = await this.refreshToken();
          if(res){
            const newTokenModel = {token: res.token, refreshToken: res.refreshToken, keepSignedIn:BaseClient.tokenModel.keepSignedIn}
            BaseClient.tokenModel = newTokenModel
            this.setToken(newTokenModel)
            return await this.request(endpoint)
          }else{
            this.setToken(null)
          }
        }
        else{
          throw response.status;
        }
      }
      else{
      const data = endpoint.responseIsText ? await response.text() : await response.json();
      return await data;
      }
  }
}